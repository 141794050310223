import { DCInput } from '@adsk/offsite-dc-sdk';
import { EMPTY_STATE_ILLUSTRATION_TYPES, FieldSetContainer, MIDEmptyState } from '@mid-react-common/common';
import Divider from '@mui/material/Divider';
import { DCInputUIExtension, FormLayoutGroup, FormRules } from 'mid-types';
import { ForgeValidationError, isFormLayoutGroup } from 'mid-utils';
import React from 'react';
import text from '../../addins.text.json';
import { productCustomizationTestIds } from '../../dataTestIds';
import { ProductCustomizationFormGroup } from './Components/ProductCustomizationFormGroup';
import { ProductCustomizationFormInputs } from './Components/ProductCustomizationFormInputs';
import {
  BottomDivider,
  ConfigureProductCustomizationHeader,
  FormLoadingSpinner,
  ProductCustomizationFormWrapper,
} from './ProductCustomization.styles';
import { mapInputsToFormLayoutRules } from './ProductCustomization.utils';

const { productCustomizationForm, productCustomizationFormLoadingSpinner, productCustomizationFormHeader } =
  productCustomizationTestIds;

interface ProductCustomizationFormProps {
  inputs: DCInput[];
  inputsError?: ForgeValidationError;
  handleInputUpdate: (payload: DCInput) => Promise<void>;
  setIsFormDataValid?: (isFormDataValid: boolean) => void;
  loading?: boolean;
  inactive?: boolean;
  error?: Error;
  errorDescription?: string | JSX.Element;
  formLayoutRules?: FormRules;
  isFormLoading?: boolean;
}

const ProductCustomizationForm: React.FC<ProductCustomizationFormProps> = ({
  inputs,
  inputsError,
  handleInputUpdate,
  setIsFormDataValid,
  inactive,
  error,
  errorDescription,
  formLayoutRules,
  isFormLoading,
}) => {
  if (isFormLoading) {
    return (
      <FieldSetContainer data-testid={productCustomizationForm}>
        <FormLoadingSpinner data-testid={productCustomizationFormLoadingSpinner} />
      </FieldSetContainer>
    );
  }

  if (inputs.length > 0 && error) {
    return (
      <FieldSetContainer data-testid={productCustomizationForm} fullWidth={!!error}>
        <MIDEmptyState
          title={error.message}
          description={errorDescription}
          illustrationType={EMPTY_STATE_ILLUSTRATION_TYPES.SYSTEM_ERROR}
        />
      </FieldSetContainer>
    );
  }

  const inputsAfterRules = mapInputsToFormLayoutRules(inputs, formLayoutRules?.inputs);

  return (
    <>
      {formLayoutRules?.formName && (
        <>
          <ConfigureProductCustomizationHeader variant="h2" data-testid={productCustomizationFormHeader}>
            {formLayoutRules?.formName}
          </ConfigureProductCustomizationHeader>
          <Divider flexItem />
        </>
      )}
      <ProductCustomizationFormWrapper>
        <FieldSetContainer data-testid={productCustomizationForm} fullWidth={!!error}>
          {inputsAfterRules.length <= 0 && (
            <MIDEmptyState title={text.formGroupIsEmpty} warning={text.formMustIncludeOneInput} />
          )}
          {inputsAfterRules.length > 0 &&
            !error &&
            inputsAfterRules.map((input: DCInputUIExtension<DCInput> | FormLayoutGroup, index) => {
              if (isFormLayoutGroup(input)) {
                return (
                  <>
                    {/** If the group is not the first one, add a divider */}
                    {index !== 0 ? <Divider flexItem /> : null}
                    <ProductCustomizationFormGroup
                      formLayoutGroup={input}
                      inputsError={inputsError}
                      inactive={inactive}
                      handleInputUpdate={handleInputUpdate}
                      setIsFormDataValid={setIsFormDataValid}
                      key={`${input.groupName}-${index}-${input.openByDefault.toString()}`}
                    />
                    {/** If the group is not the last one, add a divider */}
                    {index !== inputsAfterRules.length - 1 ? <BottomDivider flexItem /> : null}
                  </>
                );
              }
              return (
                <ProductCustomizationFormInputs
                  currentInput={input}
                  inputsError={inputsError}
                  inactive={inactive}
                  handleInputUpdate={handleInputUpdate}
                  setIsFormDataValid={setIsFormDataValid}
                  key={input.name + index}
                />
              );
            })}
        </FieldSetContainer>
      </ProductCustomizationFormWrapper>
    </>
  );
};

export default ProductCustomizationForm;
