import { OutputStatus, Variant, VariantOutput } from '@adsk/offsite-dc-sdk';
import { getVariant, getVariantOutputs } from 'mid-addin-lib';
import { AccBridgeSourceProjectDataQueryParams } from 'mid-api-services';
import { VirtualOutputStatus } from 'mid-types';
import { isVariantThumbnailOutput } from 'mid-utils';

interface PollVariantArgs {
  tenancyId: string | undefined;
  contentId: string | undefined;
  variantId: string | undefined;
  representation?: string;
  enableMultiValuesBackwardsCompatibility: boolean;
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
}

export const pollVariant = async ({
  tenancyId,
  contentId,
  variantId,
  representation,
  enableMultiValuesBackwardsCompatibility,
  incomingAccBridgeData,
}: PollVariantArgs): Promise<Variant | undefined> => {
  if (!tenancyId || !contentId || !variantId) {
    return;
  }
  const variant = await getVariant({
    projectId: tenancyId,
    productId: contentId,
    variantId,
    enableMultiValuesBackwardsCompatibility,
    incomingAccBridgeData,
  });
  const outputs = await getVariantOutputs({ variant, representation, incomingAccBridgeData });

  const polled: Variant = { ...variant };
  if (outputs.length) {
    polled.outputs = outputs;
  }

  const thumbnailOutput = polled.outputs.find((o) =>
    representation ? isVariantThumbnailOutput(o) && o.modelState === representation : isVariantThumbnailOutput(o),
  );
  polled.thumbnail = thumbnailOutput?.urn ?? polled.thumbnail;
  return polled;
};

export const areAllVariantOutputsSuccessful = (outputs: VariantOutput[]): boolean =>
  !!outputs.length && outputs.every((output) => output.status === OutputStatus.SUCCESS);

export const areAllVariantOutputsFinished = (outputs: VariantOutput[]): boolean =>
  !!outputs.length &&
  outputs.every((output) => output.status === OutputStatus.SUCCESS || output.status === OutputStatus.FAILED);

export const shouldVariantOutputsPollingContinue = (variant: Variant): boolean =>
  !!variant.outputs.length &&
  !variant.outputs.every((output) =>
    [VirtualOutputStatus.SUCCESS, VirtualOutputStatus.FAILED, VirtualOutputStatus.CANCELLED].includes(output.status),
  );
