import { ExpandableTextSection, SummaryTable, SummaryTableRow, commonText } from '@mid-react-common/common';
import Typography from '@mui/material/Typography';
import React from 'react';
import { ProductDetailsContainer } from './ProductDetails.styles';
import { addinsText } from '../..';
import { ReleaseStatusValue } from '@adsk/offsite-dc-sdk';
import { isUndefined } from 'lodash';
import { productPreviewTableTestIds } from '@mid-react-common/common';

const productDetailsText = addinsText.productDetails;

interface ProductDetailsProps {
  title?: string;
  productCategory: string;
  productFamily: string;
  representations: string[];
  releaseStatus?: ReleaseStatusValue;
  releaseNumber?: number;
  releaseNotes?: string;
}

const PRODUCT_REPRESENTATION_SEPARATOR = '; ';

export const ProductDetails: React.FC<ProductDetailsProps> = ({
  title,
  productCategory,
  productFamily,
  representations,
  releaseStatus,
  releaseNumber,
  releaseNotes,
}): JSX.Element => {
  const productDetailsData: SummaryTableRow[] = [
    {
      title: productDetailsText.revitCategoryProductDetails,
      value: productCategory,
    },
    {
      title: productDetailsText.revitFamilyProductDetails,
      value: productFamily,
    },
  ];

  if (representations.length) {
    productDetailsData.push({
      title: productDetailsText.revitRepresentation,
      value: representations.join(PRODUCT_REPRESENTATION_SEPARATOR),
    });
  }

  // There are views where we don't want to display the status &
  // number row if a value is not passed down. e.g., configure product in revit addin
  if (!isUndefined(releaseNumber) && !isUndefined(releaseStatus)) {
    productDetailsData.push(
      {
        title: productDetailsText.revitReleaseStatus,
        value: releaseStatus || '',
      },
      {
        title: productDetailsText.revitReleaseNumber,
        value: releaseNumber?.toString() || '',
      },
    );
  }

  productDetailsData.push({
    title: productDetailsText.revitReleaseNotes,
    value: (
      <ExpandableTextSection
        className={!releaseNotes ? 'mid-status-secondary' : ''}
        content={releaseNotes || commonText.releaseNotes.notesUnspecified}
      />
    ),
  });

  return (
    <ProductDetailsContainer>
      <Typography variant="h2" gutterBottom data-testid={productPreviewTableTestIds.releaseName}>
        {title}
      </Typography>
      <SummaryTable data={productDetailsData} removeBackground addRowsBorder />
    </ProductDetailsContainer>
  );
};
