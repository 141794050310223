import { StateSetter } from '@mid-react-common/common';
import { useState } from 'react';
import { ProductCustomizationTabs } from '../Data/dataStore';

export enum Screens {
  PRODUCT_SELECTION = 'PRODUCT_SELECTION',
  PRODUCT_CUSTOMIZATION = 'PRODUCT_CUSTOMIZATION',
}

export interface NavigationStore {
  currentScreen: Screens;
  setCurrentScreen: StateSetter<Screens>;
  selectedProductCustomizationTab: ProductCustomizationTabs;
  setSelectedProductCustomizationTab: StateSetter<ProductCustomizationTabs>;
}

export const useNavigationStore = (): NavigationStore => {
  const [currentScreen, setCurrentScreen] = useState(Screens.PRODUCT_SELECTION);
  const [selectedProductCustomizationTab, setSelectedProductCustomizationTab] = useState<ProductCustomizationTabs>(
    ProductCustomizationTabs.TAB_NEW,
  );

  return {
    currentScreen,
    setCurrentScreen,
    selectedProductCustomizationTab,
    setSelectedProductCustomizationTab,
  };
};
