import { DynamicContent, ProductRelease, ReleaseStatus, ReleaseStatusValue } from '@adsk/offsite-dc-sdk';
import { useCancellablePromise } from '@mid-react-common/common';
import DataContext from '../../context/Data/Data.context';
import { AccBridgeSourceProjectDataQueryParams, getDcApiServiceInstance } from 'mid-api-services';
import { logError } from 'mid-utils';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

interface UseReleasesSelectionsProps {
  projectId: string | undefined;
  selectedProduct: DynamicContent | undefined;
  areProductsFromAccBridgeIncomingFolder: boolean;
}
interface UseReleasesSelectionsState {
  selectedReleaseStatus: ReleaseStatusValue | undefined;
  selectedRelease: number | undefined;
  notesForSelectedRelease: string | undefined;
  setSelectedRelease: React.Dispatch<React.SetStateAction<number | undefined>>;
  handleReleaseSelection: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const useReleasesSelection = ({
  projectId,
  selectedProduct,
  areProductsFromAccBridgeIncomingFolder,
}: UseReleasesSelectionsProps): UseReleasesSelectionsState => {
  const dcApiService = getDcApiServiceInstance();
  const cancellablePromise = useCancellablePromise();
  const {
    setCurrentProductRelease,
    nonObsoleteProductReleasesList,
    setProductReleasesList,
    selectedIncomingBridgeDataQueryParams,
  } = useContext(DataContext);
  const [selectedRelease, setSelectedRelease] = useState<number>();
  const [selectedReleaseStatus, setSelectedReleaseStatus] = useState<ReleaseStatusValue>();
  const [notesForSelectedRelease, setNotesForSelectedRelease] = useState<string | undefined>();

  const handleReleaseSelection = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const release = event.currentTarget.innerText;
    setSelectedRelease(Number(release));
  };

  const handleReleaseSelectionStatus = useCallback(() => {
    const selectedProductRelease = nonObsoleteProductReleasesList?.find((release) => release.release === selectedRelease);
    if (selectedProductRelease) {
      setSelectedReleaseStatus(selectedProductRelease.status);
      setCurrentProductRelease(selectedProductRelease);
      setNotesForSelectedRelease(selectedProductRelease.notes);
    }
  }, [nonObsoleteProductReleasesList, selectedRelease, setCurrentProductRelease]);

  const incomingAccBridgeData = useMemo(() => {
    const queryParams: AccBridgeSourceProjectDataQueryParams = {
      sourceFolderUrn: selectedIncomingBridgeDataQueryParams?.sourceFolderUrn,
      sourceProjectId: selectedIncomingBridgeDataQueryParams?.sourceProjectId,
      targetProjectId: selectedIncomingBridgeDataQueryParams?.targetProjectId,
    };
    return queryParams;
  }, [
    selectedIncomingBridgeDataQueryParams?.sourceFolderUrn,
    selectedIncomingBridgeDataQueryParams?.sourceProjectId,
    selectedIncomingBridgeDataQueryParams?.targetProjectId,
  ]);

  useEffect(() => {
    const fetchReleases = async () => {
      if (!projectId || !selectedProduct) {
        return;
      }

      if (areProductsFromAccBridgeIncomingFolder && !incomingAccBridgeData.targetProjectId) {
        return;
      }

      let releases: ProductRelease[] = [];
      try {
        releases = await cancellablePromise(
          dcApiService.getProductReleasesList({
            projectId,
            productId: selectedProduct.contentId,
            incomingAccBridgeData,
          }),
        );
        setProductReleasesList(releases);
      } catch (error) {
        logError(error);
      }
    };

    if (projectId && selectedProduct) {
      fetchReleases();
    }
  }, [
    cancellablePromise,
    dcApiService,
    incomingAccBridgeData,
    projectId,
    selectedProduct,
    setProductReleasesList,
    areProductsFromAccBridgeIncomingFolder,
  ]);

  useEffect(() => {
    if (nonObsoleteProductReleasesList && nonObsoleteProductReleasesList.length > 0) {
      const defaultRelease = nonObsoleteProductReleasesList.find((release) => release.status === ReleaseStatus.DEFAULT);
      if (defaultRelease) {
        setSelectedRelease(defaultRelease.release);
      }
    }
  }, [nonObsoleteProductReleasesList, setSelectedRelease]);

  useEffect(() => {
    if (selectedRelease) {
      handleReleaseSelectionStatus();
    }
  }, [selectedRelease, handleReleaseSelectionStatus]);

  return { selectedRelease, setSelectedRelease, handleReleaseSelection, selectedReleaseStatus, notesForSelectedRelease };
};

export default useReleasesSelection;
