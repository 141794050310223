import { useLogAndShowNotification } from '@mid-react-common/common';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { AccProject, BIMAccount, MetaInfo } from 'mid-types';
import { useCallback, useEffect, useState } from 'react';
import text from '../../addins.text.json';
import { DropdownItem } from '../../components/Dropdown/Dropdown.types';
import { SELECTED_ACCOUNT_ID, SELECTED_PROJECT_ID } from './constants';
import { useQuery } from '@tanstack/react-query';
import { isUndefined } from 'lodash';

export interface UseACCDocSelectionState {
  accounts: DropdownItem[] | undefined;
  accountsLoading: boolean;
  projects: DropdownItem[] | undefined;
  projectsLoading: boolean;
  projectsDisabled: boolean;
  selectedAccount: MetaInfo | undefined;
  selectedProject: MetaInfo | undefined;
  handleSelectAccount: (changes: { selectedItem?: DropdownItem | null }) => void;
  handleSelectProject: (changes: { selectedItem?: DropdownItem | null }) => void;
}

export const GET_ACCOUNTS_KEY = 'getAccounts';
export const GET_PROJECTS_KEY = 'getProjects';

export const useACCDocSelection = (): UseACCDocSelectionState => {
  const [selectedAccount, setSelectedAccount] = useState<MetaInfo | undefined>();
  const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>();
  const [selectedProject, setSelectedProject] = useState<MetaInfo | undefined>();
  const [projectsDisabled, setProjectsDisabled] = useState(true);
  const forgeApiService = getForgeApiServiceInstance();

  const {
    data: accounts,
    isPending: accountsLoading,
    error: accountsError,
  } = useQuery({
    queryKey: [GET_ACCOUNTS_KEY],
    queryFn: async ({ signal }) => forgeApiService.getAccounts(signal),
  });

  useLogAndShowNotification(accountsError, text.notificationGetAccountsFailed);

  const {
    data: projects,
    isPending: projectsLoading,
    error: projectsError,
  } = useQuery({
    queryKey: [GET_PROJECTS_KEY, selectedAccountId],
    queryFn: selectedAccountId ? async ({ signal }) => forgeApiService.getProjects(selectedAccountId, signal) : undefined,
    enabled: !!selectedAccountId,
  });

  useLogAndShowNotification(projectsError, text.notificationGetProjectsFailed);

  useEffect(() => {
    if (selectedAccount?.id) {
      setProjectsDisabled(false);
      setSelectedProject(undefined);
    }
  }, [selectedAccount?.id]);

  useEffect(() => {
    const selectedAccountIdInSession = sessionStorage.getItem(SELECTED_ACCOUNT_ID);
    if (selectedAccountIdInSession && !isUndefined(accounts) && accounts.length > 0) {
      const selectedAccount = accounts.find((account) => account.id === selectedAccountIdInSession);
      if (selectedAccount) {
        setSelectedAccount({
          id: selectedAccount.id,
          name: selectedAccount.name,
        });
        setSelectedAccountId(selectedAccountIdInSession);
      } else {
        sessionStorage.removeItem(SELECTED_ACCOUNT_ID);
      }
    }
  }, [accounts]);

  const selectProject = useCallback(
    (projectId: string) => {
      const selectedProject = projects?.find((project) => project.id === projectId);

      if (selectedProject) {
        setSelectedProject({
          id: selectedProject.id,
          name: selectedProject.name,
          platform: selectedProject.platform,
        });

        sessionStorage.setItem(SELECTED_PROJECT_ID, projectId);
      } else {
        sessionStorage.removeItem(SELECTED_PROJECT_ID);
      }
    },
    [projects],
  );

  useEffect(() => {
    const selectedProjectIdInSession = sessionStorage.getItem(SELECTED_PROJECT_ID);
    if (selectedProjectIdInSession && !isUndefined(projects) && projects.length > 0) {
      selectProject(selectedProjectIdInSession);
    }
  }, [projects, selectProject]);

  const handleSelectAccount = ({ selectedItem }: { selectedItem?: DropdownItem | null }) => {
    if (selectedItem?.id) {
      const { id, value } = selectedItem;

      const idStringified = id.toString();

      setSelectedAccount({
        id: idStringified,
        name: value.toString(),
      });
      setSelectedAccountId(idStringified);
      sessionStorage.setItem(SELECTED_ACCOUNT_ID, idStringified);
    }
  };

  const handleSelectProject = useCallback(
    ({ selectedItem }: { selectedItem?: DropdownItem | null }) => {
      if (!selectedItem?.id) {
        return;
      }

      selectProject(selectedItem.id.toString());
    },
    [selectProject],
  );

  const _transformDropdownItemsForAccounts = (list: BIMAccount[] | undefined): DropdownItem[] | undefined => {
    if (list && list.length) {
      return list.map((account) => ({
        id: account.id,
        value: account.name,
        label: account.name,
      }));
    }
  };
  const _transformDropdownItemsForProjects = (list: AccProject[] | undefined): DropdownItem[] | undefined => {
    if (list && list.length) {
      return list.map((project) => ({
        id: project.id,
        value: project.name,
        label: project.name,
      }));
    }
  };

  return {
    accounts: _transformDropdownItemsForAccounts(accounts),
    accountsLoading,
    projects: _transformDropdownItemsForProjects(projects),
    projectsLoading: projectsLoading && !!selectedAccountId,
    selectedAccount,
    selectedProject,
    projectsDisabled,
    handleSelectAccount,
    handleSelectProject,
  };
};
