import { ProductRelease } from '@adsk/offsite-dc-sdk';
import { GridTwoColumns, Text } from '@mid-react-common/common';
import Stack from '@mui/material/Stack';
import React from 'react';
import text from '../../revit.text.json';
import { releaseSelectionTestIds } from '../../tests/helpers/dataTestIds';
import {
  ReleasesSelectionChip,
  ReleasesSelectionContainer,
  ReleasesSelectionGridItem,
  ReleasesWrapper,
} from './ReleasesSelection.styles';

interface ReleasesSelectionProps {
  productReleases: ProductRelease[] | undefined;
  selectedRelease: number | undefined;
  handleReleaseSelection: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ReleasesSelection: React.FC<ReleasesSelectionProps> = ({
  selectedRelease,
  productReleases,
  handleReleaseSelection,
}) => {
  if (!productReleases || productReleases.length <= 1) {
    return null;
  }

  return (
    <ReleasesSelectionContainer>
      <GridTwoColumns>
        <ReleasesSelectionGridItem data-testid={releaseSelectionTestIds.releaseSelectionSection}>
          <Text>{text.releasesSelectionTitle}</Text>
          <ReleasesWrapper>
            <Stack direction="row" spacing={1}>
              {productReleases
                .sort((a, b) => a.release - b.release)
                .map((release, index) => (
                  <ReleasesSelectionChip
                    key={`${release}-${index}`}
                    clickable
                    label={release.release}
                    variant={selectedRelease === release.release ? 'filled' : 'outlined'}
                    onClick={handleReleaseSelection}
                  />
                ))}
            </Stack>
          </ReleasesWrapper>
        </ReleasesSelectionGridItem>
      </GridTwoColumns>
    </ReleasesSelectionContainer>
  );
};

export default ReleasesSelection;
