import { midTheme } from '@mid-react-common/common';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')`
  display: flex;
  overflow: auto;
  height: calc(100vh - ${({ theme }) => `${theme.var.headerHeight + theme.var.borderHeight}px`});
`;

const productFolderBrowserHeightBuffer = 50;
export const productFolderBrowserHeight =
  midTheme.var.headerHeight +
  midTheme.var.borderHeight +
  midTheme.var.paddingBase * 2 * 2 +
  midTheme.var.dropdownHeight +
  midTheme.var.marginBase * 2 +
  midTheme.var.productFolderBrowserGridHeaderHeight +
  midTheme.var.releasesHeight +
  productFolderBrowserHeightBuffer;

export const ExistingVariantsTabContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.var.paddingBase}px`};
  height: calc(100vh - ${({ theme }) => `${theme.var.headerHeight + theme.var.borderHeight}px`});
`;

export const SelectTabContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.var.paddingBase}px`};
  height: calc(100vh - ${({ theme }) => `${theme.var.headerHeight + theme.var.borderHeight}px`});
`;

export const ScrollableContainer = styled('div')`
  flex-grow: 1;
  overflow: auto;
`;

export const PreviewContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => `${theme.var.paddingBase}px`};
`;
