import { DCRfaOutput, ReleaseStatusValue } from '@adsk/offsite-dc-sdk';
import { ProductDetails } from '@mid-react-common/addins';
import { CustomTab } from '@mid-react-common/common';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridRowParams, GridRowSelectionModel, GridValidRowModel } from '@weave-mui/data-grid';
import React from 'react';
import { productDetailsTestIds } from '../../dataTestIds';
import text from '../../revit-components.text.json';
import {
  DataGridPremiumWrapper,
  ProductDetailsTabsContainer,
  RightRefreshButtonSection,
  TabPanelContainer,
} from './ProductDetailsTabs.styles';
import { IconButton, Tooltip } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';

interface ProductDetailsTabsProps {
  tabValue: number;
  handleTabChange: (_event: React.SyntheticEvent, newValue: number) => void;
  handleRefreshVariantDataGrid: React.MouseEventHandler<HTMLButtonElement>;
  productReleaseRFAOutput?: DCRfaOutput;
  productReleaseStatus?: ReleaseStatusValue | undefined;
  productReleaseNumber?: number | undefined;
  productReleaseNotes: string | undefined;
  variantRfaRepresentations: string[];
  reFetchCachedVariants: boolean;
  isLoadingVariantsList: boolean;
  dataGridColumns: GridColDef<GridValidRowModel>[];
  dataGridRows: GridValidRowModel[];
  rowSelectionModel: GridRowSelectionModel;
  onRowSelectionModelChange: (rowSelectionModel: GridRowSelectionModel) => void;
}
const ProductDetailsTabs: React.FC<ProductDetailsTabsProps> = ({
  tabValue,
  handleTabChange,
  handleRefreshVariantDataGrid,
  productReleaseRFAOutput,
  productReleaseStatus,
  productReleaseNumber,
  productReleaseNotes,
  variantRfaRepresentations,
  reFetchCachedVariants,
  isLoadingVariantsList,
  dataGridRows,
  dataGridColumns,
  rowSelectionModel,
  onRowSelectionModelChange,
}) => {
  const theme = useTheme();

  return (
    <Box>
      <ProductDetailsTabsContainer>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <CustomTab label={text.variants} data-testid={productDetailsTestIds.variantsTab} />
          <CustomTab label={text.details} data-testid={productDetailsTestIds.detailsTab} />
        </Tabs>
        {
          // tabValue=0 means that the first tab "Variant" is selected.
          tabValue === 0 ? (
            <RightRefreshButtonSection>
              <Tooltip arrow title={text.refreshVariants}>
                <IconButton
                  size="small"
                  onClick={handleRefreshVariantDataGrid}
                  data-testid={productDetailsTestIds.refreshButton}
                >
                  <CachedIcon />
                </IconButton>
              </Tooltip>
            </RightRefreshButtonSection>
          ) : null
        }
      </ProductDetailsTabsContainer>
      <Divider />

      <ProductDetailsTabPanel value={tabValue} index={0} data-testid={productDetailsTestIds.variantsContainer}>
        <DataGridPremiumWrapper
          density="compact"
          height={!dataGridRows.length ? theme.var.dataGridEmptyLoadingHeight : undefined}
          columns={dataGridColumns}
          rows={dataGridRows}
          disableMultipleRowSelection
          loading={reFetchCachedVariants || isLoadingVariantsList}
          onRowSelectionModelChange={onRowSelectionModelChange}
          isRowSelectable={(params: GridRowParams) => params.row.createdAt !== text.failedCreatedAt}
          getRowClassName={(params: GridRowParams) =>
            params.row.createdAt === text.failedCreatedAt ? 'mid-disabled-row' : ''
          }
          rowSelectionModel={rowSelectionModel}
          sx={{ cursor: 'pointer' }}
        />
      </ProductDetailsTabPanel>
      <ProductDetailsTabPanel value={tabValue} index={1} data-testid={productDetailsTestIds.detailsContainer}>
        <ProductDetails
          productCategory={productReleaseRFAOutput?.options?.category || ''}
          productFamily={productReleaseRFAOutput?.options?.family || ''}
          representations={variantRfaRepresentations}
          releaseStatus={productReleaseStatus}
          releaseNumber={productReleaseNumber}
          releaseNotes={productReleaseNotes}
        />
      </ProductDetailsTabPanel>
    </Box>
  );
};
interface TabPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const ProductDetailsTabPanel = ({ index, value, children, ...props }: TabPanelProps) => (
  <TabPanelContainer hidden={value !== index} {...props}>
    {value === index && <>{children}</>}
  </TabPanelContainer>
);

export default ProductDetailsTabs;
