import {
  FilledFolder,
  FilledFolderOpen,
  HigFolder,
  HigFolderOpen,
  LockedFolder,
  LockedFolderOpen,
} from '@mid-react-common/common';
import { productFolderBrowser } from '../../dataTestIds';

interface TreeItemIconProps {
  useHigFolderIcons?: boolean;
  isExpanded?: boolean;
  isRoot?: boolean;
}

export const TreeItemIcon: React.FC<TreeItemIconProps> = ({ useHigFolderIcons, isExpanded, isRoot }) => {
  if (useHigFolderIcons) {
    return isExpanded ? (
      <HigFolderOpen data-testid={productFolderBrowser.treeIconHigFolderOpen} />
    ) : (
      <HigFolder data-testid={productFolderBrowser.treeIconHigFolder} />
    );
  }

  if (isRoot) {
    return isExpanded ? (
      <LockedFolderOpen data-testid={productFolderBrowser.treeIconLockedFolderOpen} />
    ) : (
      <LockedFolder data-testid={productFolderBrowser.treeIconLockedFolder} />
    );
  }

  return isExpanded ? (
    <FilledFolderOpen data-testid={productFolderBrowser.treeIconFolderOpen} />
  ) : (
    <FilledFolder data-testid={productFolderBrowser.treeIconFolderOpen} />
  );
};
