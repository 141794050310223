import { OutputType, ProductRelease, Variant } from '@adsk/offsite-dc-sdk';
import { logError, ProductReleaseError } from 'mid-utils';
import { VariantFormState, revitComponentsText, VariantFormStates } from '@mid-react-common/revit-components';
import { NOTIFICATION_STATUSES, ShowNotificationProps, StateSetter } from '@mid-react-common/common';
import { ModalState } from '@mid-react-common/revit-components/components/ProductCustomizationFooter/useProductCustomizationFooter';
import { DCProductUIExtension } from 'mid-types';

interface UseGenerateVariantProps {
  currentProductRelease: DCProductUIExtension<ProductRelease> | undefined;
  selectedRepresentation: string | undefined;
  postVariant: (tenancyId: string, contentId: string, release: number) => Promise<Variant>;
  setVariantFormState: StateSetter<VariantFormStates>;
  setReFetchCachedVariants: StateSetter<boolean>;
  dontShowAgain: boolean | undefined;
  setModalState: StateSetter<ModalState>;
  showNotification: (props: ShowNotificationProps) => void;
  handleAmplitudeVariantsGenerateEvent: (postedVariant: Variant) => void;
  setPostedVariantIdsList: StateSetter<string[]>;
}

interface UseGenerateVariantReturn {
  handleGenerateNewVariantClick: () => Promise<void>;
}

export const useGenerateVariant = ({
  currentProductRelease,
  selectedRepresentation,
  postVariant,
  setVariantFormState,
  setReFetchCachedVariants,
  dontShowAgain,
  setModalState,
  showNotification,
  handleAmplitudeVariantsGenerateEvent,
  setPostedVariantIdsList,
}: UseGenerateVariantProps): UseGenerateVariantReturn => {
  const generateNewVariant = async () => {
    try {
      if (!currentProductRelease?.contentId) {
        throw new ProductReleaseError(revitComponentsText.generateFailedProductDoesNotContainContentId, {
          currentProductRelease,
        });
      }

      setVariantFormState(VariantFormState.GENERATING_NEW_VARIANT);
      const postedVariant = await postVariant(
        currentProductRelease.tenancyId,
        currentProductRelease.contentId,
        currentProductRelease.release,
      );

      setPostedVariantIdsList((prevState) => [...prevState, postedVariant.variantId]);

      // calling Amplitude event handler
      handleAmplitudeVariantsGenerateEvent(postedVariant);

      if (hasVariantBeenPreviouslyGenerated(postedVariant, selectedRepresentation)) {
        setVariantFormState(VariantFormState.EXISTING_VARIANT_SELECTED);
        showNotification({
          message: revitComponentsText.variantPreviouslyGenerated,
          severity: NOTIFICATION_STATUSES.SUCCESS,
          autoDismiss: true,
        });
      } else {
        setReFetchCachedVariants(true);
        setVariantFormState(VariantFormState.VARIANT_RFA_OUTPUT_PENDING);
        showNotification({
          message: revitComponentsText.sentForGeneration,
          messageBody: revitComponentsText.sentForGenerationHeadline,
          severity: NOTIFICATION_STATUSES.SUCCESS,
          autoDismiss: true,
        });
      }
    } catch (err) {
      setVariantFormState(VariantFormState.EDITING_NEW_VARIANT);

      logError(err);
      showNotification({
        message: revitComponentsText.failGenerate,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  };

  const hasVariantBeenPreviouslyGenerated = (variant: Variant, variantRepresentation: string | undefined) =>
    variant?.outputs.some(
      (output) => output.type === OutputType.RFA && output.modelState === variantRepresentation && output.urn,
    ) &&
    variant?.outputs.some(
      (output) => output.type === OutputType.THUMBNAIL && output.modelState === variantRepresentation && output.urn,
    );

  const handleGenerateNewVariantClick = async () => {
    if (!dontShowAgain) {
      setModalState({
        isOpen: true,
        title: revitComponentsText.generateNow,
        message: revitComponentsText.generateModalMessage,
        confirmButtonLabel: revitComponentsText.generate,
        cancelButtonLabel: revitComponentsText.dontGenerate,
        dontShowAgainMessage: revitComponentsText.dontShowAgainMessage,
        onConfirmCallback: generateNewVariant,
      });
    } else {
      await generateNewVariant();
    }
  };
  return {
    handleGenerateNewVariantClick,
  };
};
