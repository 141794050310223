import React from 'react';
import MultiValueList from '../../MultiValueList/MultiValueList';
import TextParameterCustomization from './TextParameterCustomization';
import { DCInputUIExtension } from 'mid-types';
import { DCInput, DCInputTextType } from '@adsk/offsite-dc-sdk';

interface TextInputProps {
  input: DCInputUIExtension<DCInputTextType>;
  error?: boolean;
  label: string;
  handleInputUpdate: (payload: DCInput) => Promise<void>;
}

export const TextInput: React.FC<TextInputProps> = ({ input, error, label, handleInputUpdate }) => {
  // Multi value
  if (input.values?.length) {
    const handleTextSelectionChange = (newValue: string | number) => {
      handleInputUpdate({ ...input, value: newValue as any });
    };

    return (
      <MultiValueList
        label={label}
        items={input.values}
        value={input.value}
        error={error}
        readOnly={input.readOnly}
        onSelectionChange={handleTextSelectionChange}
        isApplicable={input.applicable}
      />
    );
  }

  // Single value
  const handleTextValueChange = (newValue: string) => handleInputUpdate({ ...input, value: newValue });
  return (
    <TextParameterCustomization
      label={label}
      value={input.value}
      error={error}
      readOnly={input.readOnly}
      doubleQuotesControl
      onTextValueChange={handleTextValueChange}
      isApplicable={input.applicable}
    />
  );
};
