import {
  LeftSection,
  RightButton,
  RightSection,
  SpinnerForButton,
  Title,
  midTheme as theme,
} from '@mid-react-common/common';
import DataContext from 'context/Data/Data.context';
import { useContext } from 'react';
import text from 'revit.text.json';
import { productSelectionTestIds } from '../../../tests/helpers/dataTestIds';
import { useInitial } from './useInitial';

const Initial: React.FC = (): JSX.Element => {
  const { currentProductRelease } = useContext(DataContext);
  const { productReleaseEnhancementWithRulesLoading, handleOpenProductClick } = useInitial();

  return (
    <>
      <LeftSection>
        <Title variant="h1">{text.subHeaderTitleInitial}</Title>
      </LeftSection>
      <RightSection>
        <RightButton
          data-testid={productSelectionTestIds.openSelectedProductReleaseButton}
          variant="contained"
          size="small"
          disabled={!currentProductRelease?.contentId}
          onClick={handleOpenProductClick}
          upperCase
        >
          {text.openProduct}
          {productReleaseEnhancementWithRulesLoading && (
            <SpinnerForButton size={theme.var.buttonSpinnerSize} color="inherit" />
          )}
        </RightButton>
      </RightSection>
    </>
  );
};

export default Initial;
