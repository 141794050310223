import { TreeItem } from '../MIDTree/MIDTree.types';

// find the item in the tree structure (can be improved with a hash map if needed)
export const searchItemToCollapse = (
  item: TreeItem | undefined,
  itemIdToCollapse: string | undefined,
): TreeItem | undefined => {
  if (!item || !itemIdToCollapse) {
    return;
  }
  if (item.id === itemIdToCollapse) {
    return item;
  }

  if (item.children) {
    item.children.forEach((child) => searchItemToCollapse(child, itemIdToCollapse));
  }
};

// find all child nodes that has to be automatically collapsed along with the clicked node by the user
export const getAllChildrenToCollapse = (item: TreeItem): string[] =>
  item.children?.flatMap((child) => [child.id, ...getAllChildrenToCollapse(child)], []) || [];
