import { midTheme as theme } from '@mid-react-common/common';
import React from 'react';
import Dropdown from '../Dropdown/Dropdown';
import useMultiValueList from './useMultiValueList';
import text from '../../addins.text.json';

interface MultiValueListProps {
  items: string[] | number[];
  value: string | number | undefined;
  readOnly?: boolean;
  error?: boolean;
  label: string;
  isApplicable?: boolean;
  onSelectionChange: (newValue: string | number) => void;
}

const MultiValueList: React.FC<MultiValueListProps> = ({
  items,
  value,
  readOnly = false,
  onSelectionChange,
  label,
  error,
  isApplicable,
}) => {
  const { dropdownItems, handleOnSelect, selectedDropdownItem } = useMultiValueList({
    onSelectionChange,
    items,
    value,
  });

  const isDisabled = isApplicable === false || readOnly;

  const selectedItem =
    selectedDropdownItem !== null && isApplicable === false
      ? { value: text.notApplicablePlaceholder, label: text.notApplicablePlaceholder }
      : selectedDropdownItem;

  return (
    <Dropdown
      label={label}
      elements={dropdownItems}
      error={error}
      onSelect={handleOnSelect}
      selectedItem={selectedItem}
      disabled={isDisabled}
      width={`${theme.var.wideInputControl}px`}
      doubleQuotesControl
    />
  );
};

export default MultiValueList;
