import { useAddinVersion } from '@mid-react-common/addins';
import { AboutInfoMenu, TopHeader } from '@mid-react-common/common';
import { VariantFormState } from '@mid-react-common/revit-components';
import React, { useContext } from 'react';
import DataContext from '../../context/Data/Data.context';
import { ProductCustomizationTabs } from '../../context/Data/dataStore';
import NavigationContext from '../../context/Navigation/Navigation.context';
import { Screens } from '../../context/Navigation/navigationStore';
import CustomizeProduct from './SubHeaders/CustomizeProduct';
import Initial from './SubHeaders/Initial';

const Header: React.FC = (): JSX.Element => {
  const { currentScreen, setCurrentScreen, setSelectedProductCustomizationTab } = useContext(NavigationContext);
  const { addinVersion, desktopApplicationVersion } = useAddinVersion();
  const {
    setSelectedCachedVariant,
    setCachedVariantsList,
    setReFetchCachedVariants,
    setCurrentProductRelease,
    setVariantFormState,
  } = useContext(DataContext);

  const handleChangeProductClick = () => {
    setCurrentScreen(Screens.PRODUCT_SELECTION);
    setCurrentProductRelease(undefined);
    setSelectedProductCustomizationTab(ProductCustomizationTabs.TAB_NEW);

    setSelectedCachedVariant(null);
    setCachedVariantsList([]);
    setReFetchCachedVariants(true);
    setVariantFormState(VariantFormState.DEFAULT_VARIANT);
  };

  return (
    <TopHeader>
      {currentScreen === Screens.PRODUCT_SELECTION && <Initial />}
      {currentScreen === Screens.PRODUCT_CUSTOMIZATION && (
        <CustomizeProduct handleChangeProductClick={handleChangeProductClick} />
      )}
      <AboutInfoMenu
        isTargetBlank
        webComponentVersion={import.meta.env.VITE_RVTW_VERSION || ''}
        addinVersion={addinVersion}
        desktopApplicationVersion={desktopApplicationVersion}
      />
    </TopHeader>
  );
};

export default Header;
