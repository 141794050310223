import React from 'react';
import MultiValueList from '../../MultiValueList/MultiValueList';
import NumericParameterCustomization from './NumericParameterCustomization';
import { DCInputUIExtension } from 'mid-types';
import { DCInput, DCInputNumericType } from '@adsk/offsite-dc-sdk';

interface NumericInputProps {
  input: DCInputUIExtension<DCInputNumericType>;
  error?: boolean;
  label: string;
  valueRange: string;
  isApplicable?: boolean;
  handleInputUpdate: (payload: DCInput) => Promise<void>;
  setIsFormDataValid?: (isFormDataValid: boolean) => void;
}

export const NumericInput: React.FC<NumericInputProps> = ({
  input,
  error,
  label,
  valueRange,
  handleInputUpdate,
  setIsFormDataValid,
}) => {
  // Multi value
  if (input.values?.length) {
    const handleNumericSelectionChange = (newValue: string | number) => {
      handleInputUpdate({ ...input, value: newValue as any });
    };

    return (
      <MultiValueList
        label={label}
        items={input.values}
        value={input.value}
        error={error}
        readOnly={input.readOnly}
        onSelectionChange={handleNumericSelectionChange}
        isApplicable={input.applicable}
      />
    );
  }

  // Single value
  const handleNumericValueChange = (newValue: number) => handleInputUpdate({ ...input, value: newValue });
  return (
    <NumericParameterCustomization
      value={input.value}
      min={input.min}
      max={input.max}
      increment={input.increment}
      readOnly={input.readOnly}
      onNumericValueChange={handleNumericValueChange}
      setIsFormDataValid={setIsFormDataValid}
      label={label}
      error={error}
      helperText={valueRange}
      isApplicable={input.applicable}
    />
  );
};
